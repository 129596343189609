import React from 'react';
import Features from '../../utils/Features';
import AdserveCard from '../../components/AdserveCard';
import coreStyles from './core.module.css'

function CoreFeatures() {
  return (
    <div>
        <h1 className={`text-[#464545] text-[20px] md:text-[30px] lg:text-[36px] font-[800] text-center mt-[5%] font-workSans  ${coreStyles.head}`}>Core Features Of<span className='text-[#5CBF14]'> AdServe</span></h1>
        <h3 className={`text-[#00000066] px-10 md:px-0 text-[15px] md:text-[20px] lg:text-[24px] font-[700] text-center mt-[1%] font-workSans leading-[150%] xl:leading-[3vw] ${coreStyles.subhead}`}>The following features shows why you should use <br className='hidden md:block' /> AdServe </h3>
        <div className='flex flex-wrap justify-around w-[90%] mx-auto mt-[3%] '>
         {
          Features.map(feature=>{
            return  <AdserveCard {...feature}/> 
          })
         }   
        </div>
    </div>
  )
}
export default CoreFeatures;