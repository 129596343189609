import React from 'react';
import lnStyles from './LearnAboutus.module.css';
import vectorBg from '../../assets/Vector (6).png';
import quote from '../../assets/Vector (8).png';
import woman from '../../assets/Woman speaking over loudspeaker.png'

function About() {
  return (
    <div className=''>
      <div className={`md:mt-0 ${lnStyles.green}`}>
        <h1 className='text-[20px] md:text-[2.7vw] font-workSans font-[700] text-white'>ABOUT US</h1>
        <span className='flex mt-[3%]'>
          <p className=' md:text-[1.3vw] font-[700] font-openSans text-[#D9D9D9]'>Home</p>
          <p className='ml-2 mr-2 md:text-[1.3vw]'><i class="fa-solid fa-greater-than md:text-[1.3vw] text-white"></i></p>
          <p className='md:text-[1.3vw] font-[700] font-openSans text-white'>About</p>
        </span>
      </div>

      <div className='flex flex-col bg-[#D9D9D9] py-8 lg:h-[700px] xl:h-[80vh]'>
          <h2 className={`${lnStyles.aboutAdserve} self-center text-[24px] md:text-[2.7vw] font-[700] font-inter text-[#51DD11]`}>About AdServe </h2>
        <div className="flex flex-col justify-between h-[80%] lg:flex-row">
          <div className={`flex justify-center relative items-center w-full h-[50vh] md:h-[60vh] lg:w-[30%] lg:h-[80%] xl:h-full ${lnStyles.announceCont}`}>
            <img src={vectorBg} alt="" className='absolute w-4/5 xl:w-[80%]'/>
            <img src={woman} alt=""  className='absolute -bottom-0 w-4/5 xl:w-[90%] '/>
          </div>
          <div className='mx-auto w-[90%] lg:w-[70%] lg:pl-[2vw]'>
            <img src={quote} alt="" className='mt-6 md:mt-[4vh] 2xl:w-[2vw]'/>
            <article className={`ml-8 ${lnStyles.article} font-workSans font-[400] leading-[35px] h-full xl:h-[80%] flex flex-col justify-around`}>
              <p className='w-[80%] md:w-[80%] text-[3vw] md:text-[2vw] xl:text-[1.3vw]'>
              AdServe™ is a platform that was designed to enable advertisers serve ads, count them, choose the ads that provide a better ROI and monitor progress of different advertising campaigns. 
              </p>
              <p  className='mt-[5%] md:mt-[2%] w-[80%] text-[3vw]  md:text-[2vw]  md:w-[80%] xl:text-[1.3vw] '>
              AdServe™ serves as a server that stores advertising materials and then distributing the material into appropriate advertising slot on websites, social media outlets and mobile apps. Throughout the application, certain features and elements are used. Understanding these features and elements will help you use the software more effectively
              </p>
              <button className={`${lnStyles.btn} mt-[5%] md:mt-[2%] lg:mt-[1%] bg-[#51DD11] w-[120px] md:w-[150px] py-1 md:py-2 text-white font-[700] text-[16px] font-openSans rounded-md 2xl:w-[15%] 2xl:py-[1%]`}>Contact us</button>
            </article>
          </div>
        </div>
      </div>
      <div className='flex flex-col mb-[20%] md:mb-[10%] lg:mb-[5%] xl:mb-0 lg:h-[800px] xl:h-[100vh] items-center mt-[5%] lg:mt-0'>
        <h1 className={`${lnStyles.learnMore} text-[20px] md:text-[30px] lg:text-[40px] leading-[60px] text-[#535252] font-bold mt-8 lg:mt-[5vh]`}>Learn More About Us Here</h1>
        <div className={`${lnStyles.videodiv} self-center`} >
            <div className='bg-[#5CBF14] h-[83px] w-[83px] rounded-full flex items-center justify-center'>
              <i className="fa-solid fa-play text-white text-[27px]"></i>
            </div>
        </div>
    </div>

    </div>
  )
}

export default About;