import React from 'react';
import feedbackStyles from './Feedback.module.css'
import ellipse1 from '../../assets/Ellipse 1.png'
import indexStyles from '../../styles/index.module.css'

function Feedback() {
  return (
    <div className={`${feedbackStyles.feedback}`}> 
    <div className={` 4xl:py-[5%] ${feedbackStyles.feedbackOverlay}`}> 
      <div className='px-4'>
          <h3 className={`text-[#5CBF14] font-[800] text-[18px] md:text-[24px] font-workSans text-center md:mt-[4rem] mt-14  ${indexStyles.feedbackHead}`}>Customers Feedbacks</h3>
            <h5 className={`md:px-[50px] lg:px-0 font-workSans font-[700] text-[20px] md:text-[36px] text-center mt-3 text-[#464545] leading-[28px] md:leading-[40px] text-[#1A1919B0] ${indexStyles.feedbackSubHead}`}>Our Clients Valuable Review About Our <br className='hidden lg:block' /> Product</h5>
      </div>
        <div>
          <div className=' w-[20%] md:w-[10%] flex justify-center items-center mx-auto '>
              <img src={ellipse1} alt="" className='w-full' />
          </div>
          <div className="p-2 md:p-4 mx-auto w-[70%] flex md:items-center justify-center flex-col">
              <p className={`font-[500] italic text-18px md:text-[20px] font-workSans leading-[25px] md:leading-[45px] lg:leading-[54px] 2xl:leading-[4vw] text-center ${indexStyles.comment}`}>“ AdServe has helped us immensely especially in the areas of <br className='hidden lg:block' /> managing our ads ourselves and it’s income across platforms. It’s <br className='hidden lg:block' /> sure helps guaranty ROI for adverts integrators.”</p>
              <p className={`text-[#5CBF14] mt-[3%] text-[20] md:text-[24px] font-[600] font-workSans text-center ${indexStyles.name}`}>- CHARLES DAMIAN
              </p>
          </div>
        </div>
       
        <div className='mx-auto flex justify-around mb-14 lg:mb-10'>
          <button className='border-solid border-[1px] border-[#5CBF14] mr-[20%] px-[1vw] '>
            <i class="fa-solid fa-chevron-left text-[#5CBF14] text-[2vw] "></i>
          </button>
          <button className=' border-solid border-[1px] border-[#5CBF14] ml-[20%] text-[2vw] px-[1vw]'>
            <i class="fa-solid fa-chevron-right text-[#5CBF14]"></i>
          </button>
        </div>
    </div>
    </div>
  )
}
export default Feedback;