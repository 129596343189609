import React from 'react';
import love from '../assets/cardbg.png';
import coreStyles from './Core-features/core.module.css';
import indexStyles from '../styles/index.module.css'

function AdserveCard({img,head,text}) {
  return (
    // <div className='h-[404px] 3xl:h-auto md:w-[40%] lg:w-[30%] xl:w-[20%] 2xl:w-[25%] 2xl:h-auto flex m-7 xl:ml-[10%] 2xl:ml-[8%] shadow-2xl items-center flex-col justify-around rounded-[5px]'>
    <div className={`md:w-[40%] lg:w-[35%] h-[404px] xl:min-h-[60vh] 4xl:min-h-[40vh] xl:w-[23%]  rounded-[0.3vw] flex flex-col m-[3%] bg-white justify-around shadow-2xl px-[2%] ${coreStyles.adserveCard}`}>
        <div className='relative self-center w-[30%] flex justify-center'>
            <img src={love} className=' w-full '/>
            <img src={img} className=' absolute bottom-[-20%] 2xl:w-[50%]'/>
        </div>
        <div className='h-[60%] flex flex-col justify-around'>
          <h1 className={`text-[16px] h-[10%] leading-[24px] self-center font-extrabold text-center  ${coreStyles.cardHead}`}>{head}</h1>
          <p className={`text-center leading-[25px] text-[16px] h-[70%] font-Opensans font-semibold 2xl:leading-[150%] 2xl:mt-[5%] px-[5%] lg:px-0 text-[#1A1919B0]  ${coreStyles.cardText}`}>{text}</p>
        </div>
    </div>
  )
}
export default AdserveCard;
