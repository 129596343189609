import React from 'react';
import formStyles from '../AdserveForm/Adserve.module.css'


function AdserveForm() {
  return (
    <div className={`${formStyles.adserve}`}>
      <div className={` ${formStyles.overlay}`}>
        <p className={formStyles.text }> Manage Advertisements Across Any Form Of Application Website With <br className='hidden lg:block' /> AdServe</p>
        <button className={`text-white text-[16px] md:text-[20px] font-[700] bg-[#51DD11] rounded-[0.3vw] px-[2.2vw] py-[0.5vw]  font-openSans ${formStyles.btn}`}>Join us now</button>
      </div>
    </div>
  )
}
export default AdserveForm;