import img1 from '../assets/Group 1.png'
import img2 from '../assets/Group 2.png'
import img3 from '../assets/Group 4.png'
import img4 from '../assets/Group 5.png'
import img5 from '../assets/Group 3.png'

const Features = [
   { 
        img: img1 ,
        head: 'Advert Content Management',
        text:  'Adverts can be placed throughout your website or mobile apps in image formats. Each adverts can be scheduled and limited to a particular amount of clicks or periods of time.'
    },
   { 
        img: img2,
        head: 'Advert Setup Module',
        text:  'This module offers a unified advertising entities and “view display configurationfor advertisement.'
    },
   { 
        img: img3,
        head: 'Subscription Swapping',
        text:  'Existing subscriptions can be modified by swapping, upgrading or downgrading a subscription.'
    },
   { 
        img: img4,
        head: 'Customer Feedback',
        text:  'This customer-centric approach can help businesses grow. This feature is a value-add to take customers complains.'
    },
   { 
        img: img5,
        head: 'Reports',
        text:  'Helps you measure your advertising ROI as well as track your image, videos, and socialnetworking adverts spendor income.'
    },
]
export default Features;