import React from 'react';
import heroStyles from './Heroe.module.css'

function Heroe() {
    return (
      <div className={heroStyles.container} >
          <div className={heroStyles.overlay}>
             <div className='ml-[6.5%] mt-[20px] text-white'>
                <h1 className={heroStyles.head}>Take Control of the Ad-Space with AdServe…</h1>
                <p className={heroStyles.texts}>AdServe helps you Place and manage adverts effectively with ease.</p>
                <p className={heroStyles.texts}>AdServe Guaranties ROI and maximum advert mindshare</p>
                <button className={`px-4 py-1 md:px-8 md:py-2 ml-2 mt-4 md:mt-7 bg-[#51DD11] rounded-[5px] text-[12px] md:text-[16px] 2xl:px-14 2xl:py-6 2xl:mt-10 3xl:mt-[5%] 3xl:py-4 2xl:text-[24px] hover:bg-[#004A00] ${heroStyles.joinus}`}>Join us</button>
             </div>
          </div>
      </div>
    )
}
export default Heroe;