import React from 'react';
import AdserveForm from '../components/AdserveForm/AdserveForm'
import Heroe from '../components/Heroe/Heroe';
import Blog from '../components/Blog';
import CoreFeatures from '../components/Core-features/CoreFeatures';
import Feedback from '../components/Feedback/Feedback';
import Advertise from '../components/advertise';


function Index() {
  return (
    <div>
      <Heroe/>
      <CoreFeatures/>
      <Advertise/>
      <Feedback/>   
      <Blog />
      <AdserveForm />
    </div>
  )
}

export default Index