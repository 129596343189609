import React from 'react';
import indexStyles from '../styles/index.module.css'

function BlogCard ({img}) {
  return (
    <div className={`w-[80%] md:w-[25%] h-[350px] md:h-[28vw] flex flex-col justify-between shadow-xl bg-[#FFFFFF] rounded-md blogCard mx-auto mt-[5%] md:mt-0 ${indexStyles.blogCard}`}> 
         <div className='h-[65%] '>
            <img src={img} className='w-full rounded-t-[0.3vw] '/>
         </div>
         <div className='h-[25%] flex flex-col justify-around'>
            <h1 className={`leading-[3.8vw] ml-[5%] text-[14px] md:text-[1.3vw] font-[600] ${indexStyles.lorem}`}>Lorem ipsum dolor sit amet.</h1>
            <button className={`text-[12px] md:text-[1.1vw] self-center text-center leading-[54px]  font-semibold text-[#5CBF14] ${indexStyles.read}`}>Read more <i className="fa-solid fa-chevron-right"></i></button>
         </div>
         <div className='h-[4px] w-[20%] bg-[#5CBF14] hover:w-full stretch'></div>
    </div>
  )
}
export default BlogCard;