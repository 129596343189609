import './App.css';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Index from './pages/index';
import About from './pages/about/about'
import Navbar from './components/Navbar/Navbar';
import FooterOne from './components/FooterOne';


function App() {
  return (
    <>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Index/>}/>
          <Route path='/about' element={<About/>}/> 
        </Routes>
        <FooterOne/>
      </Router>
    </>
  );
}
export default App;
