import React from 'react';
import BlogCard from './BlogCard';
import indexStyles from '../styles/index.module.css';
import blog1 from '../assets/Rectangle 39.png'
import blog2 from '../assets/Rectangle 40.png'
import blog3 from '../assets/Rectangle 41.png'

function Blog() {
  return (
    <div className='flex flex-col items-center justify-around xl:h-[100vh] pb-[5%]'>
        <div className='self-center text-center py-[4%]'>
            <h1 className={`text-[26px] md:text-[30px] lg:text-[32px]  font-extrabold font-workSans text-[#464545] ${indexStyles.blogHead}`}>Our Blog</h1>
            <p className={`text-[15px] md:text-[16px] font-bold font-workSans mt-[1vh] xl:mt-[2vh] text-[#00000066] ${indexStyles.blogSubHead}`}>Check out our latest updates here</p>    
        </div>
        <div className={`flex flex-col md:flex-row md:flex-wrap justify-around w-[90%] mx-auto ${indexStyles.blogCont}`}>
            <BlogCard img={blog1} />
            <BlogCard img={blog2} />
            <BlogCard img={blog3} />
        </div>
    </div>
  )
}
export default Blog;
