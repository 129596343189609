import React from 'react';
import advetising from '../assets/advertising-advetise-consumer-advertisement-icon 1.png';
import vec5 from '../assets/Vector (5).png';
import indexStyles from '../styles/index.module.css'

function Advertise() {
  return (
    <div className='flex flex-col lg:flex-row w-full mt-[10vh] '>
      <img src={advetising} alt="image of people" className='w-full lg:w-1/2 '/>
      <div className={`py-14 md:min-h-[300px] md:p-4 bg-[#5CBF14] w-full lg:w-1/2 flex items-center justify-center text-center text-18px md:text-[24px] font-[600] font-workSans text-white ${indexStyles.advert}`}>
        <img src={vec5} alt="" className=' md:mr-8' />
        With AdServeTM Advertising <br className='none md:flex' /> companies can forecast revenue in a <br className='none md:flex' /> more informed manner
      </div>
    </div>
  )
}
export default Advertise;